import React from "react";
import { useLocation } from "react-router-dom";
import { useState } from "react";
import SidebarComponent from "../../SidebarComponent";
import CalendarPComponent from "./EventDetails";

export default function CalenderPage() {
  const location = useLocation();
  const upcomingEvents: any = location.state?.upcomingEvents || [];
  const [activeTab, setActiveTab] = useState("calender");
  const [isSwitchUserModalOpen, setIsSwitchUserModalOpen] = useState(false);

  // function handleClick(e: React.MouseEvent) {
  //   const targetImage = e.target as HTMLImageElement;
  //   console.log(targetImage);
  //   if (targetImage.alt === "dashboard-image") return;
  //   setIsMobileSidebarOpen(false);
  // }
  return (
    <div className='plannerpage'>
      <SidebarComponent
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        component='planner'
        setIsSwitchUserModalOpen={setIsSwitchUserModalOpen}
      />
      <CalendarPComponent
        component='planner'
        isSwitchUserModalOpen={isSwitchUserModalOpen}
        setIsSwitchUserModalOpen={setIsSwitchUserModalOpen}
        upcomingEvents={upcomingEvents}
      />
    </div>
  );
}

import React from "react";
import { useLocation } from "react-router-dom";
import { useState } from "react";
import SidebarComponent from "../../common/SidebarComponent";
import EventDetails from "../../common/EventDetails";

export default function CalenderPage() {
  const location = useLocation();
  const upcomingEvents: any = location.state?.upcomingEvents || [];
  const [activeTab, setActiveTab] = useState("calender");
  const [isSwitchUserModalOpen, setIsSwitchUserModalOpen] = useState(false);
  
  return (
    <div className='vendorpage'>
      <SidebarComponent
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        component='vendor'
        setIsSwitchUserModalOpen={setIsSwitchUserModalOpen}
      />
      <EventDetails
        component='vendor'
        isSwitchUserModalOpen={isSwitchUserModalOpen}
        setIsSwitchUserModalOpen={setIsSwitchUserModalOpen}
        upcomingEvents={upcomingEvents}
      />
    </div>
  );
}

import React, {useState} from 'react'
import { IoMdClose } from "react-icons/io";
import { FaRegStar } from "react-icons/fa";
import { FaStar } from "react-icons/fa6";
// import profilePic from "../../assets/profilepic.svg"
import { useMyContext } from '../../ContextApi';
import axios from 'axios';
import { Spinner } from './loader';



const baseUrl = process.env.REACT_APP_API_URL;

interface VendorRatingModalProps{
    name: string,
    eventId: string,
    plannerPic: string,
    plannerName: string,
    plannerId: string,
}

export default function VendorRatingModal({name,eventId,plannerPic,plannerName,plannerId}:VendorRatingModalProps) {
    const token = sessionStorage.getItem("token")
    const {setIsVendorRatingOpen} = useMyContext()
    const [rating,setRating] = useState([
        {
            position: 0,
            status: "unfilled"
        },
        {
            position:1,
            status: "unfilled",
        },
        {
            position:2,
            status: "unfilled",
        },
        {
            position:3,
            status: "unfilled",
        },
        {
            position:4,
            status: "unfilled",
        }
    ])
    const [ratingNumber,setRatingNumber] = useState(0)
    const [comment,setComment] = useState("")
    const [isLoading,setIsLoading] = useState(false)
    const selectComment = (pos: number) =>{
        switch(pos){
            case 0:
                setComment("Very Poor");
                break;
            case 1:
                setComment("Poor");
                break;
            case 2:
                setComment("Good");
                break;
            case 3:
                setComment("Very Good");
                break;
            case 4:
                setComment("Excellent");
                break;
            default:
                setComment("")
        }
    }

    const changeRating = (pos: number) =>{
        selectComment(pos)
        setRatingNumber(pos + 1)
        let newArray;
        if(rating[pos].status === "unfilled"){
            newArray = rating.map((item)=>{
                const {position} = item
                if(position <= pos){
                    return {
                        ...item,
                        status : "filled"
                    }
                }
                else{
                    return item
                }
            })
        }else{
            newArray = rating.map((item)=>{
                const {position} = item
                if(position > pos){
                    return {
                        ...item,
                        status : "unfilled"
                    }
                }
                else{
                    return item
                }
            })
        }
        setRating(newArray)
    }

    const closeModal = () =>{
        setIsVendorRatingOpen(false)
    }
  
    const postRating = async()=>{
        if(ratingNumber === 0) return
        const endpoint = `${baseUrl}/api/ratings/planner`
        setIsLoading(true)
        try{
            const response = await axios.post(endpoint,{
                eventId,
                plannerId : plannerId,
                rating: ratingNumber,
                comment
            },{
                headers:{
                    Authorization: `Bearer ${token}`
                }
            })
            setIsLoading(false)
            if(response.status === 200){
                setIsVendorRatingOpen(false)
            }else{
                setIsVendorRatingOpen(true)
            }
        }catch(err: any){
            setIsLoading(false)
            console.log(err.message)
            setIsVendorRatingOpen(true)
        }

    }


    return (
    <div className='switch-role-modal'>
        <div onClick={closeModal} className='absolute z-10 top-0 left-0 h-full w-full'></div>
        <div className='relative z-20 switch-role-modal-main'>
            <IoMdClose  onClick={closeModal} className='cursor-pointer text-xl sm:text-2xl absolute top-2 sm:top-6 md:top-8 right-2 sm:right-6 md:right-8'/>
            <p className='px-2 text-align mb-8 font-semibold text-base sm:text-xl text-black'>Rate your last event &ldquo;{name}&rdquo;</p>
            <div className='self-stretch px-2 sm:px-6'>
                <div className='flex flex-row justify-between items-center'>
                    <div className='flex flex-row gap-2 items-center'>
                        <img className='w-[36px] h-[36px] rounded-full' src={plannerPic} alt="planner profile" />
                        <p>{plannerName}</p>
                    </div>
                    <div>
                        <div className='flex flex-row gap-2 sm:gap-3 mb-2'>
                            {
                                rating.map(({status},idx)=>{
                                    if(status === "unfilled"){
                                        return <FaRegStar 
                                                    onClick={()=>changeRating(idx)} 
                                                    key={idx} 
                                                    className='text-[#1c5d99] text-base sm:text-lg cursor-pointer' 
                                                />
                                    }else{
                                        return <FaStar
                                                    onClick={()=>changeRating(idx)}
                                                    key={idx} 
                                                    className='text-[#1c5d99] text-base sm:text-lg cursor-pointer' 
                                                />
                                    }
                                })
                            }
                        </div>
                        <p>{comment}</p>
                    </div>
                </div>
            </div>
            <div className='mt-6 flex justify-center'>
                <button onClick={postRating} className='px-6 w-[100px] rounded-lg bg-[#1c5d99] h-[40px] text-white font-semibold hover:bg-white hover:text-[#1c5d99] hover:border hover:border-[#1c5d99] transtiiton duration-500'>
                    {isLoading ? <Spinner /> : "Submit"}
                </button>
            </div>
        </div>
    </div>
  )
}

import React, { ReactNode } from "react";
import { Navigate } from "react-router-dom";
import { AppRoutes } from "./utils/AppRoutes";
//import Cookies from "js-cookie";

interface ProtectedRouteProps {
  children: ReactNode;
}

const ProtectedRoute2: React.FC<ProtectedRouteProps> = ({ children }) => {
  const token = sessionStorage.getItem("token");
  //const currentRole = Cookies.get("currentRole");

  if (!token) {
    return <Navigate to={AppRoutes.LOGIN} />;
  } else {
    return <> {children}</>;
  }
};

export default ProtectedRoute2;

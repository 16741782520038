import React, { Dispatch, SetStateAction, useState } from 'react'
import { IoMdClose } from "react-icons/io";
import { FaRegStar } from "react-icons/fa";
import { FaStar } from "react-icons/fa6";
import profilePic from "../../../../../assets/plannerpic.svg"
import { useMyContext } from '../../../../../ContextApi';
import axios from 'axios';
import { Spinner } from '../../../loader';

interface RatingCompProps{
    vendorId: string,
    vendorName: string,
    category: string,
    vendorRatingArray?: any[],
    setVendorRatingArray?:Dispatch<SetStateAction<any[]>>
}

interface PlannerRatingModalProp{
    eventName: string,
    eventId: string,
    vendors: RatingCompProps[]
}

const baseUrl = process.env.REACT_APP_API_URL;


function RatingComp({vendorId,vendorName,category,vendorRatingArray,setVendorRatingArray}:RatingCompProps){
    const [rating,setRating] = useState([
        {
            position: 0,
            status: "unfilled"
        },
        {
            position:1,
            status: "unfilled",
        },
        {
            position:2,
            status: "unfilled",
        },
        {
            position:3,
            status: "unfilled",
        },
        {
            position:4,
            status: "unfilled",
        }
    ])
    const [comment,setComment] = useState("")
    const selectComment = (pos: number) =>{
        switch(pos){
            case 0:
                setComment("Very Poor");
                return "Very Poor"
            case 1:
                setComment("Poor");
                return "Poor"
            case 2:
                setComment("Good");
                return "Good"

            case 3:
                setComment("Very Good");
                return("Very Good")
            case 4:
                setComment("Excellent");
                return "Excellent"
            default:
                setComment("")
                return ""
        }

    }

    const changeRating = (pos: number) =>{
        changeVendorRatingArray(pos)
        let newArray;
        if(rating[pos].status === "unfilled"){
            newArray = rating.map((item)=>{
                const {position} = item
                if(position <= pos){
                    return {
                        ...item,
                        status : "filled"
                    }
                }
                else{
                    return item
                }
            })
        }else{
            newArray = rating.map((item)=>{
                const {position} = item
                if(position > pos){
                    return {
                        ...item,
                        status : "unfilled"
                    }
                }
                else{
                    return item
                }
            })
        }
        setRating(newArray)
    }
    
    const changeVendorRatingArray = (pos: number) =>{
        // selectComment(pos)
        if(vendorRatingArray && setVendorRatingArray){
            const item = vendorRatingArray.find((vendorRating)=>{
                return vendorRating.vendorId === vendorId
            })
            if(!item){
                 setVendorRatingArray([
                        ...vendorRatingArray,
                        {
                            vendorId,
                            comment : selectComment(pos),
                            rating: pos + 1
                        }
                ])
            }else{
               const newArray = vendorRatingArray.map((vendorRating)=>{
                    if(vendorRating.vendorId === item.vendorId){
                        return {
                            ...vendorRating,
                            vendorId,
                            comment : selectComment(pos),
                            rating: pos + 1
                        }
                    }else{
                        return vendorRating
                    }
               })
               setVendorRatingArray([...newArray]) 
            }
        }
    }


    return (
        <div className='flex flex-row justify-between items-center'>
            <div className='flex flex-row gap-2 items-center'>
                <img className='w-[36px] h-[36px]' src={profilePic} alt="planner profile" />
                <div>
                    <p className='text-base'>{vendorName}</p>
                    <p className='text-xs'>{category}</p>
                </div>
            </div>
            <div>
                <div className='flex flex-row gap-2 sm:gap-3 mb-2'>
                    {
                        rating.map(({status},idx)=>{
                            if(status === "unfilled"){
                                return <FaRegStar 
                                            onClick={()=>changeRating(idx)} 
                                            key={idx} 
                                            className='text-[#1c5d99] text-base sm:text-lg cursor-pointer' 
                                        />
                            }else{
                                return <FaStar
                                            onClick={()=>changeRating(idx)}
                                            key={idx} 
                                            className='text-[#1c5d99] text-base sm:text-lg cursor-pointer' 
                                        />
                            }
                        })
                    }
                </div>
                <p>{comment}</p>
            </div>
        </div>
    )
} 

export default function PlannerRatingModal({eventName,eventId,vendors}:PlannerRatingModalProp) {
    const token = sessionStorage.getItem("token")
    const {setIsPlannerRatingOpen} = useMyContext()
    const [vendorRatingArray,setVendorRatingArray] = useState<any[]>([])
    const [isLoading,setIsLoading] = useState(false)
    console.log(vendorRatingArray.length,vendors.length)
    const closeModal = () =>{
        setIsPlannerRatingOpen(false)
    }

    const postRating = async() =>{
        if(vendorRatingArray.length !== vendors.length) return 
        const endpoint = `${baseUrl}/api/ratings/vendor`
        setIsLoading(true)
        try{
            const response = await axios.post(endpoint,{
                eventId,
                ratings:vendorRatingArray
            },{
                headers:{
                    Authorization: `Bearer ${token}`
                }
            })
            setIsLoading(false)
            if(response.status === 200){
                setIsPlannerRatingOpen(false)
            }else{
                setIsPlannerRatingOpen(true)
            }
        }catch(err){
            setIsLoading(false)
            setIsPlannerRatingOpen(true)
        }
    }
  return (
    <div className='switch-role-modal'>
        <div onClick={closeModal} className='absolute z-10 top-0 left-0 h-full w-full'></div>
        <div className='relative z-20 switch-role-modal-main'>
            <IoMdClose onClick={closeModal} className='cursor-pointer text-xl sm:text-2xl absolute top-2 sm:top-6 md:top-8 right-2 sm:right-6 md:right-8'/>
            <p className='px-2 sm:px-6 self-stretch mb-2 font-semibold text-base sm:text-xl text-black'>
                Rate your vendors for {eventName}
            </p>
            <p className='px-2 sm:px-6 self-stretch mb-3 text-sm'>we would like your feedbacks</p>
            <div className='self-stretch px-2 sm:px-6'>
                <div className='flex flex-col gap-2'>
                    {
                        vendors.map((vendor,idx)=>{
                            return  <RatingComp key={idx} {...vendor} vendorRatingArray={vendorRatingArray} setVendorRatingArray={setVendorRatingArray} /> 
                        })
                    }
                </div>
            </div>
            <div className='mt-6 flex justify-center'>
                <button onClick={postRating} className='px-6 w-[100px] rounded-lg bg-[#1c5d99] h-[40px] text-white font-semibold hover:bg-white hover:text-[#1c5d99] hover:border hover:border-[#1c5d99] transtiiton duration-500'>
                    {isLoading ? <Spinner /> : "Submit"}
                </button>
            </div>
        </div>
    </div>
  )
}

import React,{useEffect,useState} from 'react'
import HeaderComponent from './HeaderComponent'
import SwitchRole from './SwitchRole'
import { useMyContext } from '../../ContextApi'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import SidebarComponent from './SidebarComponent'

const baseUrl = process.env.REACT_APP_API_URL;


export default function PlannerDetails() {
    const [count,setCount] = useState(1)
    const token = sessionStorage.getItem("token");
    const [activeTab, setActiveTab] = useState("");
    const { setIsMobileSidebarOpen } = useMyContext();
    const [isSwitchUserModalOpen, setIsSwitchUserModalOpen] = useState(false);
    const [plannerDetails, setPlannerDetails] = useState({
        _id: "",
        firstName: "",
        lastName: "",
        username: "",
        email: "",
        phoneNumber: "",
        jobCategory: "",
        socialMediaHandle: "",
        homeAddress: "",
        profilePicture: ""
      });
      const { plannerId } = useParams();

    function handleClick(e: React.MouseEvent) {
        const targetImage = e.target as HTMLImageElement;
        if (targetImage.alt === "dashboard-image") return;
        setIsMobileSidebarOpen(false);
    }

    const getUser = async () => {
        const endpoint = `${baseUrl}/api/users/${plannerId}`;
        try {
          const response = await axios.get(endpoint, {
            headers: {
              Authorization: `Bearer ${token}`
            }
          });
          if (response.data.status == 200) {
            setPlannerDetails({
              _id: response.data.user._id,
              firstName: response.data.user.firstName,
              lastName: response.data.user.lastName,
              username: response.data.user.username,
              email: response.data.user.email,
              phoneNumber: response.data.user.phoneNumber,
              jobCategory: response.data.user.jobCategory,
              socialMediaHandle: response.data.user.socialMediaHandle,
              homeAddress: response.data.user.homeAddress,
              profilePicture: response.data.user.profilePicture
            });
          } else {
            setPlannerDetails({
              _id: "",
              firstName: "",
              lastName: "",
              username: "",
              email: "",
              phoneNumber: "",
              jobCategory: "",
              socialMediaHandle: "",
              homeAddress: "",
              profilePicture: ""
            });
          }
        } catch (error) {
          setPlannerDetails({
            _id: "",
            firstName: "",
            lastName: "",
            username: "",
            email: "",
            phoneNumber: "",
            jobCategory: "",
            socialMediaHandle: "",
            homeAddress: "",
            profilePicture: ""
          });
        }
      };

      useEffect(()=>{
        getUser()
      },[])

  return (
    <div className='vendor-details-page' onClick={handleClick}>
        <SidebarComponent
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            component='vendor'
            setIsSwitchUserModalOpen={setIsSwitchUserModalOpen}
        />
        {
            plannerDetails._id ? (
                <div className='vendor-details dashboard-main'>
                    <HeaderComponent pageName='planner details' count={count} setCount={setCount} />
                    <div className='vendor-details-main'>
            <div className='vendor-details-heading-box'>
              <div className='vendor-details-heading-box-top'>
                <img src={!plannerDetails.profilePicture ? "/profilePic.png" : plannerDetails.profilePicture} alt='profile-pic' />
                <div className='vendor-details-heading'>
                  <p className='vendor-name'>{`${plannerDetails.firstName} ${plannerDetails.lastName}`}</p>
                  <p className='vendor-type'>{plannerDetails.jobCategory}</p>
                </div>
              </div>
              <div className='vendor-details-rating'>
                <img src='/star-icon.svg' alt='star-icon' />
                <p>5.0</p>
              </div>
            </div>
            <div className='vendor-detail'>
              <p className='vendor-label'>Phone number</p>
              <p>{plannerDetails.phoneNumber}</p>
            </div>
            <div className='vendor-detail'>
              <p className='vendor-label'>E-mail</p>
              <p>{plannerDetails.email}</p>
            </div>
            <div className='vendor-detail'>
              <p className='vendor-label'>Instagram handle</p>
              <p>{`@${plannerDetails.socialMediaHandle}`}</p>
            </div>
          </div>
        </div>
            ) : (
                <div className='flex justify-center flex-col items-center flex-1 h-full'>
                    <p className='text-2xl text-[#1C5D99]'>planner details are not available yet</p>
                </div>
            )
        }
        {isSwitchUserModalOpen && (
            <SwitchRole
                isSwitchUserModalOpen={isSwitchUserModalOpen}
                setIsSwitchUserModalOpen={setIsSwitchUserModalOpen}
                component='vendor'
                title='Do you want switch to planner mode?'
                description=''
                btnText1='Continue as Vendor'
                btnText2='Switch to planner'
            />
        )}
    </div>
  )
}

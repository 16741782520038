import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { AppRoutes } from "../../../utils/AppRoutes";
import CalendarSvg from "../../../assets/bxs-calendar.svg";
import Calendar from "react-calendar";
import dayjs from "dayjs";
import "./CalendarStyles.css";
//import { JwtPayload } from "jwt-decode";
//import axios from "axios";

type ValuePiece = Date | null;
type Value = ValuePiece | [ValuePiece, ValuePiece];

// interface CustomJwtPayload extends JwtPayload {
//   user: {
//     _id: string;
//     jobCategory: string;
//     dateRegisteredAsVendor: string;
//   };
// }

interface IEvent {
  _id: string;
  datetime: string;
  venue: string;
  title: string;
}

//const baseUrl = process.env.REACT_APP_API_URL;

const CalendarPComponent = ({ upcomingEvents }: { upcomingEvents: IEvent[] }) => {
  const navigate = useNavigate();

  //const token = sessionStorage.getItem("token");
  const [value, onChange] = useState<Value>(new Date());
  //const [upcomingEvents, setUpcomingEvents] = useState([]);

  // const getUpcomingEvents = async () => {
  //   if (token) {
  //     const decoded: CustomJwtPayload = jwtDecode(token);
  //     const plannerId: string = decoded.user._id;
  //     try {
  //       const response = await axios.get(`${baseUrl}/api/events/search?plannerId=${plannerId}`, {
  //         headers: {
  //           Authorization: `Bearer ${token}`
  //         }
  //       });
  //       // console.log(response.status)
  //       if (response.status === 200) {
  //         setUpcomingEvents(() => {
  //           return response.data;
  //         });
  //       } else {
  //         setUpcomingEvents([]);
  //       }
  //     } catch (err) {
  //       console.log(err);
  //     }
  //   }
  // };

  const getEventForDate = (date: string) => upcomingEvents.filter((event: IEvent) => event.datetime.split("T")[0] === date)[0];

  // useEffect(() => {
  //   getUpcomingEvents();
  // }, []);

  const highlightedDates = upcomingEvents.map((upcomingEvents: IEvent) => upcomingEvents?.datetime?.split("T")[0]);

  const isHighlighted = (date: string) => highlightedDates.includes(date);

  return (
    <div className='calender-component-1'>
      <img
        onClick={() => navigate(`${AppRoutes.PLANNER.P_CALENDAR}`, { state: { upcomingEvents } })}
        className='calender-svg hidden sm:flex bg-white p-2 rounded-md'
        src={CalendarSvg}
        alt='calendar svg'
      />
      <p className='hidden sm:flex'>Calendar</p>
      <p className='hidden sm:flex'>Upcoming event</p>
      <div className='h-24 sm:h-60  mb-1 sm:mb-6 overflow-y-auto'>
        {" "}
        {upcomingEvents.length > 0 ? (
          upcomingEvents.slice(0, 3).map(({ _id, datetime, venue, title }) => {
            return (
              <div key={_id} className='upcoming-event-v'>
                <p>{dayjs(datetime).format("MMM DD, YYYY")}</p>
                <div>
                  <p>{dayjs(datetime).format("h:mm A")}</p>
                  <div></div>
                  <div>
                    <p>{venue}</p>
                    <p>{String(title).length >= 30 ? `${String(title).substring(0, 30)}...` : title}</p>
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <div className='event-empty'>
            <p className='text-[#133E66] font-[600] py-1 text-xl'>No upcoming event</p>
            <p className='text-[#133E66]'>Your calendar would be updated once you accept an event</p>
          </div>
        )}
      </div>
      <div className='hidden sm:flex justify-center'>
        <Calendar
          onChange={onChange}
          onClickDay={(value) => {
            const unixDate = dayjs(value).format("YYYY-MM-DD[T00:00:00.000Z]");
            navigate(`${AppRoutes.PLANNER.P_CALENDAR}?datetime=${unixDate}`, { state: { upcomingEvents } });
          }}
          value={value}
          tileClassName={({ date }) => {
            const dateStr = dayjs(date).format("YYYY-MM-DD");
            if (isHighlighted(dateStr)) return "react-calendar__tile highlight";
            const currentDate = new Date();
            currentDate.setHours(0, 0, 0, 0);
            if (currentDate > date) {
              return "inactive-day";
            } else {
              return "active-day";
            }
          }}
          tileContent={({ date }) => {
            const dateStr = dayjs(date).format("YYYY-MM-DD");
            const event: IEvent = getEventForDate(dateStr);
            return event ? <div className='react-calendar__tile tooltip'>{event.title}</div> : null;
          }}
          className='main-calender'
        />
      </div>
    </div>
  );
};

export default CalendarPComponent;

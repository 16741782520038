import React, { useState } from "react";
import SidebarComponent from "../../common/SidebarComponent";
import DashboardVComponent from "../../common/DashboardVComponent";
import SwitchRole from "../../common/SwitchRole";
import { useMyContext } from "../../../ContextApi";
import VendorRatingModal from "../../common/VendorRatingModal";


interface ratingDetailsObj{
  name: string,
  eventId: string,
  plannerPic: string,
  plannerName: string,
  plannerId: string
}

export default function DashboardPage() {
  const { setIsMobileSidebarOpen, isVendorRatingOpen } = useMyContext();
  const [activeTab, setActiveTab] = useState("dashboard");
  const [isSwitchUserModalOpen, setIsSwitchUserModalOpen] = useState(false);
  const [ratingDetails,setRatingDetails] = useState<ratingDetailsObj[]>([])

  function handleClick(e: React.MouseEvent) {
    const targetImage = e.target as HTMLImageElement;
    if(targetImage.alt === "profilepic"){
      setIsMobileSidebarOpen(true)
    }else if(targetImage.alt === "dashboard-image"){
      return
    }else{
      setIsMobileSidebarOpen(false);
    }
  }

  return (
    <div className='vendorpage' onClick={handleClick}>
      <SidebarComponent
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        component='vendor'
        setIsSwitchUserModalOpen={setIsSwitchUserModalOpen}
      />
      <DashboardVComponent
        setIsSwitchUserModalOpen={setIsSwitchUserModalOpen}
        setRatingDetails={setRatingDetails}
      />
      {isSwitchUserModalOpen && (
        <SwitchRole
          isSwitchUserModalOpen={isSwitchUserModalOpen}
          setIsSwitchUserModalOpen={setIsSwitchUserModalOpen}
          component='vendor'
          title='Do you want switch to planner mode?'
          description='To create an event, you need to switch to the planner.'
          btnText1='Continue as Vendor'
          btnText2='Switch to Planner'
        />
      )}
      {
        isVendorRatingOpen
        &&
        ratingDetails.map((ratingObj,idx)=>{
          return <VendorRatingModal key={idx} {...ratingObj} />
        })
      }
    </div>
  );
}

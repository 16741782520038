export const PlannerRoutes = {
  HOME: "/planner",
  NOTIFICATIONS: "/planner/notifications",
  EVENTS: "/planner/events",
  EVENT_DETAILS: "/planner/events/:eventId",
  VENDOR: "/planner/events/:eventId/offer/:offerId/:vendor",
  VENDOR_REQUEST: "/planner/events/:eventId/offer",
  EDIT_VENDOR_REQUEST: "/planner/events/:eventId/offer/:offerId/:vendor/edit",
  HISTORY: "/planner/history",
  UPCOMING_EVENTS: "/planner/upcoming_events",
  UPCOMING_EVENT_DETAILS: "/planner/upcoming_events/:eventId",
  VENDOR_DETAILS: "/vendor/:vendorId",
  CHANGE_PASSWORD: "/planner/change_password",
  SUPPORT: "/planner/support",
  REPORT_EVENT: "/planner/report_event",
  PROFILE: "/planner/profile",
  P_CALENDAR: "/planner/calendar",
};

export const AppRoutes = {
  ANY: "*",
  HOME: "/home",
  ONBOARDING: "/",
  LOGIN: "/login",
  PLANNER: PlannerRoutes,
  V_CALENDAR: "/vendor/calendar",
  V_DASH: "/vendor",
  V_ORDER: "/vendor/orderrequest",
  V_ORDER_REQUEST: "/vendor/orderrequest/:offerId",
  V_OFFER: "/vendor/offer",
  V_OFFER_DETAILS: "/vendor/offer/:offerId",
  V_HISTORY: "/vendor/history",
  V_WALLET: "/vendor/wallet",
  V_TRANSACTION_HISTORY: "/vendor/transactions",
  V_PROFILE: "/profile",
  V_PASSWORD: "/password",
  V_SUPPORT: "/support",
  V_NOTIFICATION: "/vendor/notification",
  V_PLANNER_DETAILS: "/planner-details/:plannerId",
  V_REPORT_EVENT: "/report",
  RESET_PASSWORD: "/reset-password",
  SIGNUP: "/signup"
};

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { AppRoutes } from "../../../utils/AppRoutes";
import CalendarSvg from "../../../assets/bxs-calendar.svg";
import Calendar from "react-calendar";
import dayjs from "dayjs";
import "./CalendarStyles.css";

type ValuePiece = Date | null;
type Value = ValuePiece | [ValuePiece, ValuePiece];

interface IEvent {
  _id: string;
  date: string;
  venue: string;
  description: string;
}

const CalendarVComponent = ({ upcomingEvents }: { upcomingEvents: IEvent[] }) => {
  const navigate = useNavigate();
  const [value, onChange] = useState<Value>(new Date());

  const highlightedDates = upcomingEvents.map((upcomingEvents: IEvent) => upcomingEvents?.date?.split("T")[0]);

  const isHighlighted = (date: string) => highlightedDates.includes(date);
  const getEventForDate = (date: string) => upcomingEvents.filter((event: IEvent) => event.date.split("T")[0] === date)[0];
  return (
    <div className='calender-component-1'>
      <img onClick={() => navigate(AppRoutes.V_CALENDAR)} className='calender-svg' src={CalendarSvg} alt='calendar svg' />
      <p>Calendar</p>
      <p>Upcoming event</p>
      {upcomingEvents.length > 0 ? (
        upcomingEvents.slice(0, 3).map(({ _id, date, venue, description }) => {
          return (
            <div key={_id} className='upcoming-event-v'>
              <p>{dayjs(date).format("MMM DD, YYYY")}</p>
              <div>
                <p>{dayjs(date).format("h:mm A")}</p>
                <div></div>
                <div>
                  <p>{venue}</p>
                  <p>{description}</p>
                </div>
              </div>
            </div>
          );
        })
      ) : (
        <div className='event-empty'>
          <p>No upcoming event</p>
          <p>Your calendar would be updated once you accept an event</p>
        </div>
      )}
      <div>
        <Calendar
          onChange={onChange}
          onClickDay={(value) => {
            const unixDate = dayjs(value).format("YYYY-MM-DD[T00:00:00.000Z]");
            navigate(`${AppRoutes.V_CALENDAR}?datetime=${unixDate}`, { state: { upcomingEvents } });
          }}
          tileClassName={({ date }) => {
            const dateStr = dayjs(date).format("YYYY-MM-DD");
            if (isHighlighted(dateStr)) return "react-calendar__tile highlight";

            const currentDate = new Date();
            currentDate.setHours(0, 0, 0, 0);

            if (currentDate > date) {
              return "inactive-day";
            } else {
              return "active-day";
            }
          }}
          tileContent={({ date }) => {
            const dateStr = dayjs(date).format("YYYY-MM-DD");
            const event: IEvent = getEventForDate(dateStr);
            return event ? <div className='react-calendar__tile tooltip'>{event.description}</div> : null;
          }}
          value={value}
          className='main-calender'
        />
      </div>
    </div>
  );
};

export default CalendarVComponent;

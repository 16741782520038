import React, { useState, ChangeEvent, useRef} from "react";
import SidebarComponent from "../../common/SidebarComponent";
import WalletVComponent from "../../common/WalletVComponent";
import SwitchRole from "../../common/SwitchRole";
import {useMyContext } from "../../../ContextApi";
import axios from "axios";
import SuccessModal from "../../common/Planner/Modals/SuccessModal/SuccessModal2";
import WalletDecline from "../../common/Planner/Modals/CancelandDeclineModals/WalletDeline";
import { PaystackButton } from 'react-paystack'

const baseUrl = process.env.REACT_APP_API_URL;
const publicKey = process.env.REACT_APP_PAYSTACK_PUBLIC_KEY || ""


export default function WalletPage() {
  const id = sessionStorage.getItem("_id")
  const email= sessionStorage.getItem("email") || ""
  const token = sessionStorage.getItem("token")
  const {setWallet,setIsMobileSidebarOpen,setTransaction} = useMyContext();
  const [activeTab, setActiveTab] = useState("wallet");
  const [walletModal, setWalletModal] = useState(false);
  const [walletModalCount, setWalletModalCount] = useState(0);
  const [amount, setAmount] = useState("");
  const [isSwitchUserModalOpen, setIsSwitchUserModalOpen] = useState(false);
  const appToken = useRef(0);
  const [verifyPaymentModal,setVerifyPaymentModal] = useState(false)
  const [tokenMessage,setTokenMessage] = useState("")
  const [isPaymentFailed,setIsPaymentFailed] = useState(false)
  const inputRef = useRef<HTMLInputElement>(null)

  function closeModal(){
    setIsPaymentFailed(false)
  }
  function convertToken(amount : string){
    const amountInt = parseInt(amount.replace(/[^0-9]/g, ""))
    if(Number.isNaN(amountInt)){
      appToken.current = 0
    }else{
      const noOfToken = (amountInt / 100).toFixed(1)
      appToken.current =  Number(noOfToken)

    }
  }

  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    const amount = formatPrice(e.target.value)
    if(amount != "₦" && amount != ""){
      convertToken(amount)
      setAmount(amount); 
    }else{
      setAmount("")
    }
  }
  function handleClick(e: React.MouseEvent) {
    const targetImage = e.target as HTMLImageElement;
    if (targetImage.alt === "dashboard-image") return;
    setIsMobileSidebarOpen(false);
  }

  function handleClick2(e: React.MouseEvent) {
    const targetImage = e.target as HTMLImageElement;
    if (targetImage.className === "wallet-modal") {
      setWalletModal(false);
      setAmount("");
      if(walletModalCount == 1){
        setWalletModalCount(0)
      }
    }
  }

  function handleClick3() {
    const formatAmount = amount.replace(/[^0-9]/g, "")
    if(formatAmount !== ""){
      setWalletModalCount(1);
      setAmount(formatAmount)
    }
  }

  function amountFunc(cash: string) {
    setAmount(formatPrice(cash));
    convertToken(cash)
  }

  const formatPrice = (inputValue: string) => {
    const numericValue = inputValue.replace(/[^0-9]/g, "");

    const formattedValue = `₦${numericValue.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;

    return formattedValue;
  };
  const verifyPayment = async(reference: string)=>{
    setWalletModal(false)
    const verifyEndpoint = `${baseUrl}/api/paystack/verify/${reference}`
    if(reference){
      try{
        const response = await axios.get(verifyEndpoint,{
          headers : {
            Authorization: `Bearer ${token}`
          }
        })
        if(response.status === 200){
          getWallet()
          setTokenMessage(response.data.message)
          setVerifyPaymentModal(true)
        }
      }catch(error){
        setVerifyPaymentModal(false)
        setIsPaymentFailed(true)
        console.log(error)
      }
    }
  }

  async function getWallet(){
    const endpoint = `${baseUrl}/api/users/${id}/wallet/`
    try{
      const response = await axios.get(endpoint,{
        headers:{
          Authorization : `Bearer ${token}`
        }
      })
      if(response.status === 200){
        const reversedTransaction = response.data.wallet.transactions.reverse()
        setTransaction([...reversedTransaction])
        setWallet({
          tokenBalance: response.data.wallet.tokenBalance,
          transactions: [...reversedTransaction],
          _id: response.data.wallet._id
        }) 
      }
    }catch(error){
      setWallet({
        tokenBalance: 0,
        transactions: [],
        _id: ""
      })
      setTransaction([])
    }
  }

  const handleFocus = () =>{
    if(inputRef.current){
      inputRef.current.focus()
    }
  }

  const componentProps = {
    email,
    amount : parseInt(amount) * 100,
    publicKey,
    text: "pay with paystack",
    onSuccess: (response: any) =>{
      verifyPayment(response.reference)
    },
    onClose: () => {
      setWalletModal(false)
      setIsPaymentFailed(true)
      setWalletModal(false)
    },
  }


  return (
    <div className='vendorpage' onClick={handleClick}>
      <SidebarComponent
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        component='vendor'
        setIsSwitchUserModalOpen={setIsSwitchUserModalOpen}
      />
      <WalletVComponent walletModal={walletModal} setWalletModal={setWalletModal} />

      {walletModal && activeTab === "wallet" && (
        <div className='wallet-modal' onClick={handleClick2}>
          <div className='wallet-modal-main'>
            {walletModalCount === 0 ? (
              <div>
                <div className='wallet-container'>
                  <div>
                    <p>Top up</p>
                    <p>How much token do you want?</p>
                  </div>
                  <div>
                    <div className={amount === "1000" ? "selected" : ""} onClick={() => amountFunc("1000")}>
                      <p>{formatPrice("1000")}</p>
                      <p>10 token</p>
                    </div>
                    <div className={amount === "2000" ? "selected" : ""} onClick={() => amountFunc("2000")}>
                      <p>{formatPrice("2000")}</p>
                      <p>20 token</p>
                    </div>
                    <div className={amount === "3000" ? "selected" : ""} onClick={() => amountFunc("3000")}>
                      <p>{formatPrice("3000")}</p>
                      <p>30 token</p>
                    </div>
                    <div className={amount === "10000" ? "selected" : ""} onClick={() => amountFunc("10000")}>
                      <p>{formatPrice("10000")}</p>
                      <p>100 token</p>
                    </div>
                    <div onClick={() => amountFunc("20000")} className={amount === "20000" ? "selected" : ""}>
                      <p>{formatPrice("20000")}</p>
                      <p>200 token</p>
                    </div>
                  </div>
                  <div>
                    <p onClick={handleFocus} className="cursor-pointer">Different amount?</p>
                    <input type='text' ref={inputRef} placeholder='amount' value={amount} onChange={handleChange} />
                    {amount != "" && amount != "₦" ? (
                      <p>
                        {amount} gives {appToken.current} token
                      </p>
                    ) : null}
                  </div>
                </div>
                <button onClick={handleClick3}>
                  <p>Continue</p>
                  <svg className="moveleft" xmlns='http://www.w3.org/2000/svg' width='21' height='20' viewBox='0 0 21 20' fill='none'>
                    <path
                      d='M11.5567 3.05737C11.8067 2.80741 12.1458 2.66699 12.4993 2.66699C12.8529 2.66699 13.192 2.80741 13.442 3.05737L19.442 9.05737C19.692 9.30741 19.8324 9.64649 19.8324 10C19.8324 10.3536 19.692 10.6927 19.442 10.9427L13.442 16.9427C13.1905 17.1856 12.8537 17.32 12.5041 17.3169C12.1546 17.3139 11.8201 17.1737 11.5729 16.9265C11.3257 16.6793 11.1855 16.3448 11.1825 15.9952C11.1794 15.6456 11.3138 15.3088 11.5567 15.0574L15.166 11.3334H2.49935C2.14573 11.3334 1.80659 11.1929 1.55654 10.9429C1.30649 10.6928 1.16602 10.3537 1.16602 10C1.16602 9.64642 1.30649 9.30728 1.55654 9.05723C1.80659 8.80718 2.14573 8.66671 2.49935 8.66671H15.166L11.5567 4.94271C11.3067 4.69267 11.1663 4.35359 11.1663 4.00004C11.1663 3.64649 11.3067 3.30741 11.5567 3.05737Z'
                      fill='white'
                    />
                  </svg>
                </button>
              </div>
            ) : walletModalCount === 1
            ?(
              <div className='wallet-container2'>
                <p>How would you like to pay</p>
                <PaystackButton className="payment-method cursor-pointer" {...componentProps}/>
              </div>
            )
            : <></>
          }
          </div>
        </div>
      )}
      {isSwitchUserModalOpen && (
        <SwitchRole
          isSwitchUserModalOpen={isSwitchUserModalOpen}
          setIsSwitchUserModalOpen={setIsSwitchUserModalOpen}
          component='vendor'
          title='Do you want switch to planner mode?'
          description='To create an event, you need to switch to the planner.'
          btnText1='Continue as Vendor'
          btnText2='Switch to Planner'
        />
      )}
      {
        verifyPaymentModal
        &&
        <SuccessModal
          message="Congratulations!"
          description={tokenMessage}
          closeModal={()=>setVerifyPaymentModal(false)}
        />
      }
      {
        isPaymentFailed
        &&
        <WalletDecline
          message={"your transaction was unseccessful"}
          description={"Please try again!"}
          btnText={"Try again"} 
          closeModal={closeModal}
        />
      }
    </div>
  );
}
